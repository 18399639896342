import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import chunk from 'lodash/chunk';

const InfiniteScrollComponent = ({ className, children = [] }) => {
	const chunkedList = chunk(children, 15);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [renderedList, setRenderedList] = useState(chunkedList[0]);

	const loadMore = () => {
		const listLength = chunkedList.length - 1;
		if (currentIndex === listLength) return;
		setTimeout(() => {
			const nextIndex = currentIndex + 1;
			setCurrentIndex(nextIndex);

			const newList = [...renderedList, ...chunkedList[nextIndex]];
			setRenderedList(newList);
		}, 1500);
	};

	return (
		<InfiniteScroll
			className={className}
			dataLength={renderedList.length}
			next={loadMore}
			hasMore={true}
			loader={
				<React.Fragment>
					{renderedList.length !== children.length && (
						<div className="mt5 mt10--md pl1 tc x sans--24 sans--36--md">
							Loading More...
						</div>
					)}
				</React.Fragment>
			}
		>
			{renderedList}
		</InfiniteScroll>
	);
};

export default InfiniteScrollComponent;
